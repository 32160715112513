<template>
  <div>
    <b-navbar toggleable="lg" variant="primary">
      <template v-if="!isAuthorized">
        <b-navbar-nav>
          <b-nav-item v-bind:to="'/login'">Вход</b-nav-item>
        </b-navbar-nav>
      </template>
      <template v-else>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item v-if="!isRolesEmpty" v-bind:to="'/orders'">К списку заказов</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item @click="logoutClicked" right>Выход</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </template>
    </b-navbar>
    <loading-spinner v-if="busy"/>
    <router-view v-else/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import LoadingSpinner from "@/components/util/LoadingSpinner.vue";

export default {
  name: "app",
  components: {
    'loading-spinner': LoadingSpinner
  },
  mounted() {
    document.title = "Заказы";
    this.getOrderStatuses()
  },
  data() {
    return {
      busy: false,
    }
  },
  computed: {
    ...mapGetters([
      "isAuthorized",
      "isRolesEmpty",
      "isAdminOrProvider"
    ])
  },
  methods: {
    ...mapActions(["logout", "initStatuses"]),
    logoutClicked() {
      this.logout()
          .finally(() => {
            this.$router.push("/login");
          });
    },
    async getOrderStatuses() {
      this.busy = true;
      await this.initStatuses()
          .finally(() => {
                this.busy = false
              }
          )
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/color-scheme.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>
