import {ORDER_AXIOS, SHOP_ORDER_URL,} from "@/modules/backend-config";

let sseEventSource;

export default {
    getOrderList(pageSize, pageNumber, sortBy, sortDirection, filter) {
        return ORDER_AXIOS.get('/allOrders', {
            params: {
                pageSize,
                pageNumber,
                sortBy,
                sortDirection,
                startDate: filter.dateStart || null,
                endDate: filter.dateEnd || null,
                costStart: filter.costStart || null,
                costEnd: filter.costEnd || null,
                status: filter.status || null,
                paymentStatus: filter.paymentStatus || null,
                phone: filter.phone || null
            }
        });
    },
    getOrder(id) {
        return ORDER_AXIOS.get(`/${id}`);
    },
    updateOrderItem(item, order_id) {
        return ORDER_AXIOS.put(`/${order_id}/products/${item.id}`, item);
    },
    updateOrder(order) {
        return ORDER_AXIOS.put(`/${order.id}`,
            order, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
    },
    getOrderStatuses() {
        return ORDER_AXIOS.get("/orderStatuses")
    },
    getPaymentStatuses() {
        return ORDER_AXIOS.get("/paymentStatuses")
    },
    cloneOrder(orderId) {
        return ORDER_AXIOS.post('/clone', {
            orderId
        });
    },
    decline(orderId) {
        return ORDER_AXIOS.post(`/${orderId}/declinePayment`);
    },
    refund(orderId, opInfo) {
        return ORDER_AXIOS.post(`/${orderId}/refundPayment`, opInfo, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    deposit(orderId, opInfo) {
        return ORDER_AXIOS.post(`/${orderId}/deposit`, opInfo, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    getStreamEvent(id) {
        sseEventSource = new EventSource(SHOP_ORDER_URL + `/sse/${id}/order-operation-complete`);
        return new Promise((resolve, reject) => {
            sseEventSource.onmessage = (e) => {
                resolve(JSON.parse(e.data))
                };
            }
        )
    },
    closeEventStream() {
        sseEventSource.close()
    }
}
