<template>
  <b-container class="pt-2">
    <back-button text="К списку заказов" path="/orders"/>
    <item-info :item-get-function="getOrder" :fields="fields">
      <template #orderDate="{orderDate}">
        <b-form-input readonly :value="new Date(orderDate).toLocaleString()"/>
      </template>
      <template #deliveryDate="{deliveryDate}">
        <b-form-input readonly :value="convertDeliveryDate(deliveryDate)"/>
      </template>
      <template #deliveryType="{deliveryType}">
        <b-form-input readonly :value="convertDeliveryType(deliveryType)"/>
      </template>
      <template #deliveryAddress="{deliveryAddress}">
        <b-form-input readonly :value="convertDeliveryAddress(deliveryAddress)"/>
      </template>
      <template #deliveryCost="{deliveryCost}">
        <b-form-input readonly :value="convertDeliveryCost(deliveryCost)"/>
      </template>
      <template #status="{status}">
        <b-form-input readonly :value="getStatusByName(status)"/>
      </template>
      <template #paymentStatus="{paymentStatus}">
        <b-form-input readonly :value="getPaymentStatusByName(paymentStatus)"/>
      </template>
      <template #paymentType="{paymentType}">
        <b-form-input readonly :value="stringUtils.convertPaymentType(paymentType)"/>
      </template>
    </item-info>
    <b-list-group>
      <label for="items">Заказанные товары</label>
      <b-list-group-item id="items" class="border-0 p-0 mb-2" v-for="product in items" :key="product.id">
        <product-details :order="order" :product=product :read-only="true"/>
      </b-list-group-item>
    </b-list-group>
  </b-container>
</template>

<script>
import orderService from "@/modules/orders-service";
import stringUtils from "@/utils/string-utils";
import ProductDetails from "@/views/orders/orderdetail/product/ProductDetails.vue";
import {mapGetters} from "vuex";
import BackButton from "@/components/buttons/BackButton.vue";

export default {
  components: {
    BackButton,
    'product-details': ProductDetails,
  },
  name: "order-details-edit",
  computed: {
    ...mapGetters([
      "getStatusByName",
      "getPaymentStatusByName",
    ]),
    stringUtils() {
      return stringUtils
    }
  },
  data() {
    return {
      fields: [
        {key: "id", label: "ID"},
        {key: "totalCost", label: "Сумма заказа (руб.)"},
        {key: "orderDate", label: "Дата заказа"},
        {key: "deliveryDate", label: "Дата доставки"},
        {key: "deliveryType", label: "Тип доставки"},
        {key: "deliveryCost", label: "Стоимость доставки"},
        {key: "deliveryAddress", label: "Адрес доставки"},
        {key: "paymentType", label: "Тип оплаты"},
        {key: "customerFullName", label: "ФИО покупателя"},
        {key: "customerEmail", label: "E-mail покупателя"},
        {key: "customerPhone", label: "Телефон покупателя"},
        {key: "status", label: "Статус заказа"},
        {key: "paymentStatus", label: "Статус Оплаты"},
      ],
      order: {
        id: Number,
        status: String
      },
      items: []
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    async getOrder() {
      let order = (await orderService.getOrder(this.$route.params.orderId)).data;
      this.order = {id: order.id, status: order.status};
      this.items = order.products
      return order;
    },
    convertDeliveryType(deliveryType) {
      return stringUtils.convertDeliveryType(deliveryType)
    },
    convertDeliveryDate(deliveryDate) {
      return deliveryDate == null
          ? '-'
          : new Date(deliveryDate).toLocaleDateString();
    },
    convertDeliveryAddress(deliveryAddress) {
      return deliveryAddress == null
          ? "-"
          : `Улица ${deliveryAddress.street}, дом ${deliveryAddress.building}, квартира ${deliveryAddress.flat}`;
    },
    convertDeliveryCost(deliveryCost) {
      return deliveryCost == null
          ? "-"
          : deliveryCost;
    },
  }
}
</script>
