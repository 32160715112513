<template>
    <b-col md="auto">
      <b-badge class="fitCont" v-if="showIf" pill variant="primary">{{ text }}</b-badge>
    </b-col>
</template>

<script>
export default {
  name: 'pill',
  props:{
    text:{
      type: String,
      required: true,
    },
    showIf:{
      type: Boolean,
      required: true,
    }
  },
}
</script>

<style scoped>
.fitCont{
  width: fit-content !important;
  block-size: fit-content !important;
}
</style>