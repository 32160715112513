<template>
  <div>
    <b-button class="mb-2" variant="primary" @click="confirmPress" v-if="showIf"
              :disabled="disabled" style="width: 10em">
      {{ text }}
    </b-button>
  </div>
</template>
<script>
export default {
  name: 'paymentButton',
  emits: ['clicked'],
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    showIf: {
      type: Boolean,
      required: true,
    }
  },
  methods: {
    confirmPress() {
      this.$bvModal
          .msgBoxConfirm(`Вы уверены, что хотите выполнить '${this.text}'?`, {
            title: 'Подтверждение',
            titleTag: 'h6',
            okVariant: 'success',
            okTitle: 'Да',
            cancelTitle: 'Нет',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
                if (!value) {
                  return;
                }
                this.$emit('clicked')
              }
          );
    }
  }
}
</script>
