import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/auth/Login.vue';
import store from "@/store";
import OrderList from "@/views/orders/OrderList.vue";
import OrderDetailsEdit from "@/views/orders/orderdetail/OrderDetailsEdit.vue";
import OrderDetailsRead from "@/views/orders/orderdetail/OrderDetailsRead.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/login', component: Login, meta: {title: 'Вход'}},
        {path: "/orders", component: OrderList, meta: {title: 'Список заказов'}},
        {path: "/orders/:orderId", component: OrderDetailsRead, meta: {title: 'Детализация заказа'}},
        {path: "/orders/:orderId/edit", component: OrderDetailsEdit, meta: {title: 'Изменение детализации заказа'}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }

    if (!store.getters.isAuthorized && '/login' !== to.path) {
        return next({path: '/login', query: {redirect: to.path}});
    }
    if (store.getters.isAuthorized && '/login' === to.path) {
        return next('/orders');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/orders' !== to.path) {
        return next('/orders');
    }
    if ('/' === to.path) {
        return next('/orders');
    }
    return next();
});

export default router;
