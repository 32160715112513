<template>
  <div>
    <b-button v-if="showIf" v-b-toggle="collapse" class="w-100" variant="outline-primary"
              @click="$emit('onClick')"
              style="border-radius: 0 !important; color: black">
      {{ text }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: "wide-outlined-btn",
  emits: ["onClick"],
  props: {
    showIf: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    collapse: {
      type: String,
      required: true,
    }
  },
}
</script>
