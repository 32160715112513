import axios from 'axios';
import axiosUtils from "@/utils/axios-utils";

const SHOP_ORDER_URL = "/api/shop-order";

const AUTH_AXIOS = axios.create({
    baseURL: '/api/ident',
    timeout: 300000
})

const ORDER_AXIOS = axios.create({
    baseURL: SHOP_ORDER_URL,
    timeout: 3000
})

const CATALOG_AXIOS = axios.create({
    baseURL: '/api/server-catalog',
    timeout: 3000
})

axiosUtils.addUnauthorizedInterceptor(ORDER_AXIOS)

export {AUTH_AXIOS, ORDER_AXIOS, CATALOG_AXIOS, SHOP_ORDER_URL}
