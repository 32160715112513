<template>
  <b-row no-gutters class="mb-1">
    <b-col md="auto" class="mr-1" align-self="center">
      {{ text }}
    </b-col>
    <b-col>
      <b-input-group>
        <b-input :formatter="integerFormatter"
                 placeholder="Сумма заказа"
                 aria-describedby="input-live-help input-live-feedback"
                 @input="val => $emit('input', val)"
                 :value="value"/>
        <template #append>
          <b-button variant="outline" class="grey-border" @click="$emit('input', null)">
            <b-icon-x-circle variant="danger"/>
          </b-button>
        </template>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    text: String,
    value: String
  },
  methods: {
    integerFormatter(value) {
      let numericValue = value.replace(/\D/g, '');
      if (numericValue.length > 15) {
        numericValue = numericValue.slice(0, -1);
      }
      return numericValue;
    }
  }
}
</script>
