<template>
  <div>
    <b-button size="sm" variant="primary" v-bind:to="path">
      <b-icon-arrow-left/>
      {{ text }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: "back-button",
  props: {
    path: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    }
  },
}
</script>
