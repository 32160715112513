<template>
  <div>
    <wide-outlined-btn collapse="collapse-shipment" text="Поставка товара" :show-if="product.weighed"
                       @onClick="() => collapseVisible = !collapseVisible"/>
    <b-collapse v-model="collapseVisible">
      <b-form @submit.stop.prevent="$emit('onSubmit', product)" style="max-width: 350px;" class="p-3 text-left">

        <b-form-group>
          <b-form-radio v-model="product.shipment" v-bind:value="true">Поставлю</b-form-radio>
          <b-form-radio v-model="product.shipment" v-bind:value="false">Не поставлю</b-form-radio>
          <b-form-radio v-model="product.shipment" v-bind:value="null">Не определен</b-form-radio>
        </b-form-group>

        <div v-if="product.shipment">
          <b-form-group label="Дата выгрузки в распределительный центр">
            <b-form-input type="date"
                          :min="curTime.format('YYYY-MM-DD')"
                          v-model="product.shipmentDate"
                          id="shipment-date-validation"
                          style="min-width: 120px; max-width: 300px;"
                          :state="$v.product.shipmentDate.$dirty ? !$v.product.shipmentDate.$error : null">
            </b-form-input>
            <b-form-invalid-feedback id="shipment-date-validation">
              Необходимо указать дату выгрузки
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="product.weighed" label="Вес товара">
            <b-form-input type="number"
                          id="finalQuantity-validation"
                          style="min-width: 120px; max-width: 300px;"
                          v-model.number="product.weighed ? product.finalWeight : product.finalQuantity"
                          :state="$v.product.finalQuantity.$dirty ? !$v.product.finalQuantity.$error : null"/>
            <b-form-invalid-feedback id="finalQuantity-validation">
              Необходимо указать положительный вес товара, который меньше заказанного количества
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <b-form-group v-if="product.shipment === false" label="Комментарий">
          <b-form-textarea v-model="product.comment"
                           placeholder="Комментарий"
                           style="min-width: 120px; max-width: 300px;"
                           id="comment-validation"
                           :state="$v.product.comment.$dirty ? !$v.product.comment.$error : null">
          </b-form-textarea>
          <b-form-invalid-feedback id="comment-validation">
            Необходимо написать комментарий
          </b-form-invalid-feedback>
        </b-form-group>

        <b-button v-if="readOnly" variant="primary" type="submit">Сохранить</b-button>
      </b-form>
    </b-collapse>

    <wide-outlined-btn collapse="collapse-marked" text="Маркированный товар" :show-if="product.marked && !readOnly"/>
    <b-collapse id="collapse-marked">
      <div style="max-width: 350px;" class="p-3">
        <b-form-group label="Маркировка товара">
          <b-form-input type="text"
                        v-model="product.markingCode"
                        style="min-width: 120px; max-width: 300px;">
          </b-form-input>
        </b-form-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import moment from "moment/moment";
import WideOutlinedBtn from "@/components/buttons/WideOutlinedButton.vue";

export default {
  components: {WideOutlinedBtn},
  props: {
    product: {
      id: Number,
      name: String,
      thumbnailPath: String,
      price: Number,
      quantity: Number,
      comment: String,
      shipment: Boolean,
      weighed: Boolean,
      shipmentDate: Date,
      marked: Boolean,
      markingCode: String,
      finalQuantity: Number,
      finalWeight: Number,
      measurementUnit: String,
      measurementStep: Number,
    },
    readOnly: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      collapseVisible: false,
      curTime: moment()
    }
  },
  inject: ['$v'],
}
</script>
