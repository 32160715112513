export default {
    convertPaymentType(paymentType) {
        switch (paymentType) {
            case "ONLINE":
                return "Онлайн";
            case "CASH":
                return "Наличными";
            default:
                return "";
        }
    },
    convertDeliveryType(deliveryType) {
        switch (deliveryType) {
            case "BY_ADDRESS":
                return "По адресу";
            case "PICKUP":
                return "Самовывоз";
            default:
                return "";
        }
    },
}
