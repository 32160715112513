import {AUTH_AXIOS} from './backend-config';

export default {
    login(phone) {
        return AUTH_AXIOS.post('/login', {
            phone
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    loginByPassword(username, password) {
        return AUTH_AXIOS.post('/login', {}, {
            headers: {
                'Authorization': 'Basic ' + btoa(`${username}:${password}`)
            }
        });
    },
    authenticate(phone, password) {
        return AUTH_AXIOS.post('/auth', {
            phone,
            password
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    logout() {
        return AUTH_AXIOS.post('/logout', {}, {});
    },
    getRoles() {
        return AUTH_AXIOS.get("/my_roles", {
            params: {
                serviceName: "ORDERS"
            }
        });
    },
    refreshToken() {
        return AUTH_AXIOS.put('/refresh', {});
    },
    changePassword(login, password, newPassword) {
        return AUTH_AXIOS.put('/change_password', newPassword,
            {
                headers: {
                    'Authorization': 'Basic ' + btoa(`${login}:${password}`),
                    'Content-Type': 'text/plain'
                }
            });
    },
    getAuthType() {
        return AUTH_AXIOS.get('/auth_type');
    }
}
