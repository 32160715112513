<template>
  <b-container class="pt-2">

    <div class="d-flex justify-content-between mb-5 mt-2">
      <back-button text="К списку заказов" path="/orders"/>
      <b-button v-if="isAdminOrManager" :disabled="isClone" size="sm" variant="primary" @click="cloneOrder">
        <loading-spinner v-if="isCloneCreating"></loading-spinner>
        <span v-else>Создать клон заказа</span>
      </b-button>
    </div>

    <b-form v-if="orderDetails.status" class="mb-3" @submit.stop.prevent="onSubmit()">
      <b-row cols-md="3" cols-sm="1">
        <readonly-form-input :model-value="orderDetails.id" label="ID"/>
        <readonly-form-input :model-value="orderDetails.totalCost" label="Сумма заказа (руб.)"/>
        <readonly-form-input :model-value="new Date(orderDetails.orderDate).toLocaleString()" label="Дата заказа"/>
        <readonly-form-input :model-value="convertDeliveryDate(orderDetails.deliveryDate)" label="Дата доставки"/>
        <readonly-form-input :model-value="convertDeliveryType(orderDetails.deliveryType)" label="Тип доставки"/>
        <readonly-form-input :model-value="convertDeliveryCost(orderDetails.deliveryCost)" label="Стоимость доставки"/>
        <readonly-form-input :model-value="convertDeliveryAddress(orderDetails.deliveryAddress)"
                             label="Адрес доставки"/>
        <readonly-form-input :model-value="convertPaymentType(orderDetails.paymentType)" label="Тип оплаты"/>
        <readonly-form-input :model-value="orderDetails.customerFullName" label="ФИО покупателя"/>
        <readonly-form-input :model-value="orderDetails.customerEmail" label="E-mail покупателя"/>
        <readonly-form-input :model-value="orderDetails.customerPhone" label="Телефон покупателя"/>
        <readonly-form-input :model-value="getPaymentStatusByName(orderDetails.paymentStatus)" label="Статус оплаты"/>
      </b-row>

      <b-form-group label="Статус заказа">
        <b-form-select v-model="orderDetails.status"
                       :disabled="!isStatusChangingEnabled()"
                       :options="getStatuses"
                       value-field="value"
                       text-field="text"/>
      </b-form-group>

      <b-col>
        <b-row>
          <payment-button :show-if="this.showExecutePayment" :disabled="!isAdmin && paymentExecuted"
                          text="Списать средства" @clicked="executePayment()"/>
          <payment-button :show-if="refundPermitted && showRefund" :disabled="!isAdmin && refundExecuting"
                          text="Выполнить возврат" @clicked="executeRefund()"/>
          <payment-button :show-if="declinePermitted && showDecline" :disabled="!isAdmin && declineExecuting"
                          text="Отмена заказа" @clicked="executeDecline()"/>
        </b-row>
        <b-row class="mb-2" v-if="orderDetails.status === 'IN_PROCESS'">
          <product-create :products="this.orderDetails.products" @updateCost="updateCost"/>
        </b-row>
      </b-col>

      <b-form-group label="Заказанные товары">
        <b-list-group>
          <b-list-group-item class="border-0 p-0 mb-2" v-for="product in orderDetails.products" :key="product.id">
            <product-details :order="order" :product=product @deleteItem="deleteItem"
                             @updateCost="updateCost"/>
          </b-list-group-item>
        </b-list-group>
      </b-form-group>

      <b-button v-if="isAdminOrManager" variant="primary" class="w-100 mt-4" type="submit" :disabled="submitting">
        Сохранить
      </b-button>
      <b-button class="w-100 mt-4" @click="$router.back()">
        Назад
      </b-button>

    </b-form>
    <loading-spinner v-else/>
  </b-container>
</template>

<script>
import orderApi from "@/modules/orders-service";
import stringUtils from "@/utils/string-utils";
import {mapGetters} from "vuex";
import ProductDetails from "@/views/orders/orderdetail/product/ProductDetails.vue";
import ProductCreate from "@/components/dropdown/ProductCreate.vue";
import ReadonlyFormInput from "@/components/colitems/ReadonlyFormInput.vue";
import PaymentButton from "@/components/buttons/PaymentButton.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import toastUtils from "@/utils/toast-utils";

export default {
  components: {
    BackButton,
    PaymentButton,
    ReadonlyFormInput,
    ProductCreate,
    'product-details': ProductDetails,
  },
  data() {
    return {
      productsVisible: false,
      submitting: false,
      paymentExecuted: false,
      refundExecuting: false,
      // нужен только когда ушел запрос, т.к. операции не подтягиваются повторно
      showRefund: true,
      declineExecuting: false,
      showDecline: true,
      isCloneCreating: false,
      showExecutePayment: false,
      orderDetails: {
        id: '',
        totalCost: '',
        orderDate: '',
        deliveryDate: '',
        deliveryType: '',
        deliveryCost: '',
        deliveryAddress: '',
        paymentType: '',
        customerFullName: '',
        customerEmail: '',
        customerPhone: '',
        status: '',
        paymentStatus: '',
        products: [],
      },
      order: {
        id: Number,
        status: String
      }
    };
  },
  watch: {
    $route() {
      this.fetchOrder(this.$route.params.orderId);
    },
  },
  mounted() {
    orderApi.getStreamEvent(this.$route.params.orderId)
        .then(event => {
          if (event.status) {
            this.orderDetails.status = event.status
          }
          if (event.paymentStatus) {
            this.orderDetails.paymentStatus = event.paymentStatus
          }
        })

    this.fetchOrder(this.$route.params.orderId)
    document.title = this.$route.meta.title
  },
  beforeDestroy() {
    orderApi.closeEventStream()
  },
  computed: {
    ...mapGetters([
      "isAdminOrManager",
      "isAdmin",
      "isManager",
      "getStatuses",
      "getPaymentStatusByName",
    ]),
    isClone() {
      return this.orderDetails?.status === 'CLONE';
    },
    updatedTotalCost() {
      let total = 0
      this.orderDetails.products.forEach(product => {
        let quantity = product.weighed ? product.finalWeight ?? (product.quantity * product.measurementStep) : product.finalQuantity ?? product.quantity
        total += product.price * quantity / product.measurementStep
      })

      this.orderDetails.totalCost = total
    },
    refundPermitted() {
      return Array.isArray(this.orderDetails?.operations) &&
          this.orderDetails.operations.some(op => op.paymentCompleted && !op.failed && op.completedStatus === 'PAID') &&
          !this.orderDetails.operations.some(op => !op.paymentCompleted && !op.failed && op.completedStatus === 'REFUND') &&
          this.orderDetails.totalCost > 0
    },
    declinePermitted() {
      return this.orderDetails &&
          (this.orderDetails.status === 'FUNDS_RESERVED' || this.orderDetails.status === 'NEW') &&
          !this.orderDetails.operations?.some(op => !op.failed && op.completedStatus === 'CANCELLED')
    },
  },
  methods: {
    async fetchOrder(id) {
      this.orderDetails = await orderApi.getOrder(id)
          .then(resp => {
            this.showExecutePayment = resp.data.paymentAvailable
            this.showRefund = true
            this.showDecline = true
            return resp.data;
          });
      this.order = {id: this.orderDetails.id, status: this.orderDetails.status};
    },
    convertDeliveryType(deliveryType) {
      return stringUtils.convertDeliveryType(deliveryType)
    },
    convertDeliveryDate(deliveryDate) {
      return deliveryDate == null
          ? '-'
          : new Date(deliveryDate).toLocaleDateString();
    },
    convertDeliveryAddress(deliveryAddress) {
      return deliveryAddress == null
          ? "-"
          : `Улица ${deliveryAddress.street}, дом ${deliveryAddress.building}, квартира ${deliveryAddress.flat}`;
    },
    convertDeliveryCost(deliveryCost) {
      return deliveryCost == null
          ? "-"
          : deliveryCost;
    },
    convertPaymentType(paymentType) {
      return stringUtils.convertPaymentType(paymentType)
    },
    onSubmit() {
      if (this.orderDetails.products.some(value => !value.quantity)) {
        toastUtils.error(this.$bvToast, 'Введите количество товара большее 0.')
        return
      }

      this.submitting = true;
      this.handleResponse(orderApi.updateOrder(this.orderDetails));
    },
    cloneOrder() {
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите создать клон заказа?', {
            title: 'Клонирование заказа',
            titleTag: 'h6',
            okVariant: 'success',
            okTitle: 'Создать',
            cancelTitle: 'Отмена',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              this.isCloneCreating = true;
              orderApi.cloneOrder(this.orderDetails.id)
                  .then(response => {
                    this.$router.push(`/orders/${response.data.id}/edit`);
                    toastUtils.success(this.$bvToast, 'Клон заказа успешно создан. Вы находитесь на его странице')
                  })
                  .catch(error => {
                    console.error(error);
                    if (error.response != null) {
                      if (error.response.status === 403) {
                        this.errorMessage = "Недостаточно прав для клонирования заказа";
                      } else if (error.response.status === 401) {
                        this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
                      } else if (error.response.status === 404) {
                        this.errorMessage = "Не удалось найти заказ";
                      } else {
                        this.errorMessage = "Возникла ошибка при клонировании заказа";
                      }
                    } else {
                      this.errorMessage = "Возникла ошибка при клонировании заказа";
                    }
                    toastUtils.error(this.$bvToast, this.errorMessage +
                        (error.response == null ?
                            ''
                            : ` (код ${error.response.status})`))
                  })
                  .finally(() => {
                    this.isCloneCreating = false;
                  });
            }
          });
    },
    handleResponse(axiosCall) {
      axiosCall
          .then(() => {
            this.$router.go(0);
            toastUtils.success(this.$bvToast, "Состояние заказа успешно сохранено")
          })
          .catch(e => {
            if (e.isAxiosError) {
              if (e.response.status === 403) {
                this.errorMessage = "Недостаточно прав для сохранения заказа";
              } else if (e.response.status === 409) {
                this.errorMessage = "Недостаточно товара на складе";
              } else {
                this.errorMessage = "Возникла ошибка при сохранении заказа";
              }
              toastUtils.error(this.$bvToast, this.errorMessage)
            }
            throw e;
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    deleteItem(itemId) {
      this.orderDetails.products = this.orderDetails.products.filter(obj => obj.id !== itemId);
    },
    updateCost() {
      this.updatedTotalCost
    },
    executePayment() {
      this.paymentExecuted = true;
      let depositObj = {
        totalCost: this.orderDetails.totalCost,
        /*
           TODO: вернуть сюда товары, когда будет их нормальное редактирование
           этот массив отвечает за ВОВЗРАТ, т.е. будет списано меньше денег, чем было зарезервировано

        products: this.orderDetails.products
                      .map(prod => {
                        return { id: prod.itemId, quantity: prod.quantity }
                      })
        */
      }

      orderApi.deposit(this.order.id, depositObj)
          .then(resp => {
            toastUtils.success(this.$bvToast, "Запрос на списание средств отправлен")
            this.showExecutePayment = false
            // this.order.status = resp.status;
          }).catch(resp => {
        toastUtils.error(this.$bvToast, "Ошибка при попытке запроса на списание средств")
      }).finally(() => {
        this.paymentExecuted = false
      })
    },
    executeRefund() {
      this.refundExecuting = true;
      let refundObj = {
        totalCost: this.orderDetails.totalCost,
        products: this.orderDetails.products
            .map(prod => {
              return {id: prod.itemId, quantity: prod.quantity}
            })
      }

      orderApi.refund(this.order.id, refundObj)
          .then(resp => {
            toastUtils.success(this.$bvToast, "Запрос на возврат средств отправлен")
            this.showRefund = false
          }).catch(resp => {
        toastUtils.error(this.$bvToast, "Ошибка при попытке запроса на возврат средств")
      }).finally(() => {
        this.refundExecuting = false
      })
    },
    executeDecline() {
      this.declineExecuting = true;
      orderApi.decline(this.order.id)
          .then(resp => {
            toastUtils.success(this.$bvToast, "Запрос на отмену заказа отправлен")
            this.showDecline = false
          }).catch(resp => {
        toastUtils.error(this.$bvToast, "Ошибка при попытке запроса отмены заказа")
      }).finally(() => {
        this.declineExecuting = false
      })
    },
    isStatusChangingEnabled() {
      return (this.orderDetails.paymentStatus === 'PAID' || this.orderDetails.paymentStatus === 'FUNDS_RESERVED') && this.isAdminOrManager
    },
  },
}
</script>

<style>
.overflowed_label {
  overflow: auto;
  white-space: nowrap;
}
</style>
