<template>
  <b-input-group class="mt-1">
    <b-input :value="value"
             @input="val => $emit('input', val)"
             :formatter="phoneFormatter"
             placeholder="Номер телефона"/>
    <template #append>
      <b-button variant="outline" class="grey-border" @click="$emit('input', null)">
        <b-icon-x-circle variant="danger"/>
      </b-button>
    </template>
  </b-input-group>
</template>

<script>
export default {
  props: {
    text: String,
    value: String
  },
  methods: {
    phoneFormatter(value) {
      return value.replace(/\D/g, '')
          .substring(0, 11)
          .replace(/(\d)?(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/,
              (match, p1, p2, p3, p4, p5) =>
                  (p1 ? `+${p1}` : '') +
                  (p2 ? `-${p2}` : '') +
                  (p3 ? `-${p3}` : '') +
                  (p4 ? `-${p4}` : '') +
                  (p5 ? `-${p5}` : ''))
    },
  }
}
</script>
