<template>
  <b-container class="pt-2">
    <div v-if="isRolesEmpty">
      <h1 class="my-4">У вас нет доступа к данному сервису</h1>
    </div>
    <div v-else>
      <filter-card v-model="filters" @refresh="onRefresh"/>
      <b-table
          hover
          responsive
          :tbody-tr-class="rowClass"
          :items="getOrders"
          :fields="fields"
          :current-page="orderList.currentPage"
          ref="order_table"
          :per-page="orderList.perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="table-bordered table-hover"
          show-empty>
        <template v-slot:empty="scope">
          <h6 class="text-center">Заказы отсутствуют</h6>
        </template>
        <template v-slot:table-busy>
          <loading-spinner/>
        </template>
        <template v-slot:cell(orderDate)="row">
          {{
            new Date(row.item.orderDate).toLocaleString('ru-RU',
                {
                  hour12: false, hour: '2-digit', minute: '2-digit',
                  day: '2-digit', month: '2-digit',
                  year: 'numeric'
                })
          }}
        </template>
        <template v-slot:cell(status)="row">
          {{ getStatusByName(row.item.status) }}
        </template>
        <template v-slot:cell(paymentStatus)="row">
          {{ getPaymentStatusByName(row.item.paymentStatus) }}
        </template>
        <template v-slot:cell(deliveryDate)="row">
          {{ row.item.deliveryDate == null ? 'Самовывоз' : new Date(row.item.deliveryDate).toLocaleDateString() }}
        </template>
        <template v-slot:cell(interaction)="row">
          <b-button variant="outline-primary"
                    title="Просмотр"
                    v-bind:to="`/orders/${row.item.id}`">
            <b-icon-eye-fill/>
          </b-button>
          <b-button class="ml-1" variant="outline-primary"
                    v-if="isAdminOrManager"
                    title="Редактирование"
                    v-bind:to="`/orders/${row.item.id}/edit`">
            <b-icon-pencil-fill/>
          </b-button>
        </template>
      </b-table>
      <div class="d-flex mb-5">
        <div class="col-md-2 pl-0">
          <b-form-select
              v-model="orderList.perPage"
              class="text-light border-secondary bg-secondary">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </b-form-select>
        </div>
        <span class="align-self-center d-none d-lg-block mb-2">записей на страницу</span>
        <b-pagination
            v-model="orderList.currentPage"
            class="my-0 ml-auto mb-2"
            :total-rows="orderList.totalCount"
            :per-page="orderList.perPage"
            aria-controls="template-table"
            @input="getOrders">
        </b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import orderService from "@/modules/orders-service";
import {mapGetters, mapMutations, mapState} from "vuex";
import FilterCard from "@/components/filter/FilterCard.vue";
import toastUtils from "@/utils/toast-utils";

export default {
  name: 'orders',
  components: {FilterCard},
  data() {
    return {
      sortBy: 'orderDate',
      sortDesc: true,
      fields: [
        {
          key: 'id', label: 'ID', sortable: true
        },
        {
          key: 'orderDate', label: 'Дата заказа', sortable: true
        },
        {
          key: 'deliveryDate', label: 'Дата доставки', sortable: true
        },
        {
          key: 'totalCost', label: 'Сумма заказа (руб.)', sortable: true
        },
        {
          key: 'status', label: 'Статус заказа', sortable: true
        },
        {
          key: 'paymentStatus', label: 'Статус оплаты', sortable: true
        },
        {
          key: 'customerFullName', label: 'ФИО', sortable: true
        },
        {
          key: 'customerPhone', label: 'Телефон', sortable: true
        },
        {
          key: 'interaction', label: 'Взаимодействие', sortable: false
        }
      ],
      orderList: {
        perPage: 10,
        currentPage: 1,
        totalCount: 0,
      },
      filters: {},
    };
  },
  created() {
    document.title = this.$route.meta.title;
    this.filters = this.filterData;
  },
  computed: {
    ...mapState({
      filterData: state => state.orderFilter,
    }),
    ...mapGetters([
      "isAdminOrManager",
      "isRolesEmpty",
      "getStatusByName",
      "getPaymentStatusByName",
    ]),
  },
  methods: {
    getOrders(context) {
      return orderService.getOrderList(context.perPage, context.currentPage, context.sortBy,
          context.sortDesc ? "Descending" : "Ascending", this.filters)
          .then(resp => {
            this.orderList.totalCount = resp.data.totalCount;
            return resp.data.orders;
          })
          .catch(error => {
            toastUtils.error(this.$bvToast, 'Не удалось загрузить заказы' + (error.response == null ? '' : ` (код ${error.response.status})`))
          });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return
      }

      const countProblems = item.products.reduce((prev, item) => item.weighed && !item.shipment ? prev + 1 : prev, 0)
      if (countProblems === 1) {
        return 'table-warning'
      } else if (countProblems > 1) {
        return 'table-danger'
      }
    },
    onRefresh() {
      this.$refs.order_table.refresh()
      this.updateFilters(this.filters)
    },
    ...mapMutations('orderFilter', {
      updateFilters: 'setFilters'
    }),
  }
}
</script>
