import {CATALOG_AXIOS} from "@/modules/backend-config";

export default {
    getByQuery(query, pageSize, pageNumber) {
        return CATALOG_AXIOS.get(`/product/queryNotEmpty`, {
            params: {
                query: query || '',
                pageNumber: pageNumber,
                pageSize: pageSize,
                sortName: 'name',
                sortDirection: 'Asc',
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

    },
    getOrderList(productCode) {
        return CATALOG_AXIOS.get(`/product/ordering`, {
            params: {
                code: productCode
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    getProduct(id) {
        return CATALOG_AXIOS.get(`/product/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}
