<template>
  <div>
    <b-button variant="primary"
              :variant="!editingEnabled ? 'primary' : 'success'"
              @click="$emit('changeUpdateState')">
      <b-icon-check2 v-if="editingEnabled"/>
      <b-icon-pencil v-else/>
    </b-button>
    <b-button variant="primary"
              class="mx-2"
              @click="$emit('setDeletingItem',product.id)">
      <b-icon icon="trash"/>
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    editingEnabled: Boolean,
    product: {
      id: Number
    }
  }
}
</script>