import Vuex from 'vuex'
import Vue from 'vue';
import authModule from '@/store/auth';
import statuses from "@/store/statuses";
import orderFilter from "@/store/orderFilter";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: authModule,
        statuses: statuses,
        orderFilter: orderFilter
    }
});
