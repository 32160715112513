<template>
  <div class="py-2">
    <b-button v-b-toggle.filters-collapse variant="primary">Фильтры</b-button>
    <b-collapse id="filters-collapse" class="mt-2">
      <b-card>
        <b-row>
          <b-col>
            <b-row cols="2">
              <b-col class="pt-1">
                <date-item v-model="value.dateStart" text="От:"/>
              </b-col>
              <b-col class="pt-1">
                <date-item v-model="value.dateEnd" text="До:"/>
              </b-col>
              <b-col>
                <number-filter v-model="value.costStart" text="От:"/>
              </b-col>
              <b-col>
                <number-filter v-model="value.costEnd" text="До:"/>
              </b-col>
              <b-col>
                <selector-filter v-model="value.status" text="Выберите статус заказа"
                                 :options="getStatuses"/>
              </b-col>
              <b-col>
                <selector-filter v-model="value.paymentStatus" text="Выберите статус оплаты"
                                 :options="getPaymentStatuses"/>
              </b-col>
              <b-col>
                <phone-filter v-model="value.phone"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="auto">
            <div class="p-1">
              <b-button variant="primary" @click="handleClick()" class="w-100">Поиск</b-button>
            </div>
            <div class="p-1">
              <b-button variant="primary" @click="clearState()">Очистить всё</b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import DateItem from "@/components/filter/DateFilter.vue";
import NumberFilter from "@/components/filter/NumberFilter.vue";
import SelectorFilter from "@/components/filter/SelectorFilter.vue";
import PhoneFilter from "@/components/filter/PhoneFilter.vue";
import {mapGetters} from "vuex";

export default {
  name: 'filter-card',
  components: {PhoneFilter, SelectorFilter, NumberFilter, DateItem},
  computed: {
    ...mapGetters([
      "getStatuses",
      "getPaymentStatuses",
    ])
  },
  props: {
    value: {
      type: Object,
      required: true,
    }
  },
  methods: {
    clearState() {
      this.value.dateStart = null
      this.value.dateEnd = null
      this.value.costStart = null
      this.value.costEnd = null
      this.value.phone = null
      this.value.status = null
      this.value.paymentStatus = null
      this.$emit("input", this.value)
    },
    handleClick() {
      this.$emit("input", this.value)
      this.$emit('refresh')
    },
  }
}
</script>
