import ordersService from "@/modules/orders-service";

export default {
    state: {
        statuses: [],
        paymentStatuses: [],
    },
    actions: {
        async initStatuses({commit}){
            commit("setStatuses", (await ordersService.getOrderStatuses()).data);
            commit("setPaymentStatuses", (await ordersService.getPaymentStatuses()).data);
        },
    },
    mutations: {
        setStatuses(state, statuses) {
            state.statuses = statuses
        },
        setPaymentStatuses(state, statuses) {
            state.paymentStatuses = statuses
        }
    },
    getters: {
        getStatuses: state => {
            return state.statuses;
        },
        getStatusByName: (state) => (orderStatus) =>{
            return state.statuses[orderStatus] || 'Not found';
        },
        getPaymentStatuses: state => {
            return state.paymentStatuses;
        },
        getPaymentStatusByName: (state) => (orderStatus) =>{
            return state.paymentStatuses[orderStatus] || 'Not found';
        }
    }
};
