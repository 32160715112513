import ordersService from "@/modules/orders-service";

export default {
  namespaced: true,
  state: {
      dateStart : '',
      dateEnd : '',
      costStart : '',
      costEnd : '',
      phone: '', 
      status: null,
      paymentStatus: null,
  },
  actions: {
  },
  mutations: {
    setFilters(state, filterData) {
      state.dateStart = filterData.dateStart
      state.dateEnd = filterData.dateEnd
      state.costStart = filterData.costStart
      state.costEnd = filterData.costEnd
      state.phone = filterData.phone
      state.status = filterData.status
      state.paymentStatus = filterData.paymentStatus
    }
  },
};
