<template>
  <b-input-group class="mt-1">
    <b-select :value="value"
              @change="val => $emit('input', val)"
              :options="options">
      <template #first>
        <b-form-select-option :value="null" disabled>{{ text }}</b-form-select-option>
      </template>
    </b-select>
    <template #append>
      <b-button variant="outline" class="grey-border" @click="$emit('input', null)">
        <b-icon-x-circle variant="danger"/>
      </b-button>
    </template>
  </b-input-group>
</template>

<script>
export default {
  props: {
    text: String,
    value: String,
    options: Object
  },
}
</script>