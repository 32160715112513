export default {
    error(toast, text) {
        toast.toast(text, {
            title: 'Ошибка',
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true,
        })
    },
    success(toast, text) {
        toast.toast(text, {
            variant: 'success',
            solid: true,
            title: 'Успешно',
            autoHideDelay: 5000,
            noCloseButton: true,
            appendToast: true,
        });
    }
}
