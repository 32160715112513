<template>
  <b-row no-gutters class="mb-2">
    <b-col md="auto" class="mr-1" align-self="center">
      {{ text }}
    </b-col>
    <b-col>
      <b-input-group>
        <b-datepicker :value="value" @input="val => $emit('input', val)"
                      locale="ru" placeholder="Введите дату"/>
        <template #append>
          <b-button variant="outline" class="grey-border" @click="$emit('input', null)">
            <b-icon-x-circle variant="danger"/>
          </b-button>
        </template>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    text: String,
    value: String
  },
}
</script>
<style>
.grey-border {
  border-color: rgb(206, 212, 218)
}
</style>