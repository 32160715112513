<template>
  <div class="w-100">
    <b-col>
      <b-form-group :label="label" label-class="overflowed_label">
        <b-form-input readonly
                      :value="modelValue"/>
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "readonly-form-input",
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
  }
}
</script>
