<template>
  <b-container class="p-0">
    <b-card no-body class="overflow-hidden">
      <b-row no-gutters>
        <b-col class="mx-auto mx-md-0" style="max-width: 200px;" md="6">
          <b-card-img style="max-width: 200px; max-height: 200px" :src="product.thumbnailPath"
                      class="rounded-0"/>
        </b-col>

        <b-col>
          <b-card-body :title="product.name" :sub-title="'#' + product.id">
            <b-card-text>
              <b-col>
                <b-row>
                  <span>Заказано <span class="special">{{ getOrderedAMount }} {{
                      product.measurementUnit
                    }}</span></span>
                </b-row>

                <b-row align-v="center">

                  <b-col class="pdl pdr" style="padding-top: 8px; width: 100px;" md="auto">
                    <b-form-input v-if="product.weighed"
                                  type="text" v-model="product.finalWeight" :disabled="!editingEnabled"
                                  id="weight-validation"
                                  md="auto" :placeholder="initialAmount.toString()" :formatter="numericFormat"
                                  :state="$v.product.finalWeight.$dirty ? !$v.product.finalWeight.$error : null"/>
                    <b-form-input v-else
                                  type="text" v-model="product.finalQuantity" :disabled="!editingEnabled"
                                  id="amount-validation"
                                  md="auto" :placeholder="initialAmount.toString()" :formatter="numericFormat"
                                  :state="$v.product.finalQuantity.$dirty ? !$v.product.finalQuantity.$error : null"/>
                  </b-col>

                  <b-col class="pdl">
                    {{ product.measurementUnit }} × {{ product.price }} ₽/ {{ getMeasurementStep }}
                    {{ product.measurementUnit }}
                  </b-col>

                </b-row>
                <b-row>
                  <div id="weight-validation" v-if="$v.product.finalWeight.$error" class="error-feedback">
                    Необходимо указать положительный вес товара, который не больше заказанного
                  </div>
                  <div id="amount-validation" v-if="$v.product.finalQuantity.$error" class="error-feedback">
                    Необходимо указать положительное количество товара, который не больше заказанного
                  </div>
                </b-row>

              </b-col>
            </b-card-text>

            <b-row>
              <pill :show-if="product.weighed" text="Весовой товар"/>
              <pill :show-if="product.marked" text="Маркированный товар"/>
            </b-row>

          </b-card-body>
        </b-col>

        <b-col md="auto" v-if="!readOnly && canEdit">
          <b-row align-v="center" align-h="end" class="text-right m-1 ">
            <div v-if="this.product.weighed && !this.product.shipment"
                 :title="getTip"
                 class="text-right circle" style="margin-right: 12px"/>
            <action-buttons :editing-enabled="editingEnabled" :product="product" @changeUpdateState="handleEditClick"
                            @setDeletingItem="setDeletingItem"/>
          </b-row>
        </b-col>

      </b-row>

      <b-card-footer v-if="canEdit && (editingEnabled || readOnly)" style="padding: 0 !important;">
        <product-footer :read-only="readOnly" :product="product" @onSubmit="onSubmit"/>
      </b-card-footer>

    </b-card>
  </b-container>
</template>

<script>
import orderService from "@/modules/orders-service";
import moment from "moment";
import catalogService from "@/modules/catalog-service";
import ActionButtons from "@/views/orders/orderdetail/product/ActionButtons.vue";
import ProductFooter from "@/views/orders/orderdetail/product/ProductFooter.vue";
import Pill from "@/components/colitems/Pill.vue";
import toastUtils from "@/utils/toast-utils";

export default {
  name: "product-details",
  components: {Pill, ProductFooter, ActionButtons},
  provide() {
    return {
      '$v': this.$v,
    }
  },
  data() {
    return {
      deletingId: '',
      editingEnabled: false,
      submitting: false,
      curTime: moment(),
      initialAmount: 0,
    };
  },
  props: {
    product: {
      id: Number,
      name: String,
      thumbnailPath: String,
      price: Number,
      quantity: Number,
      comment: String,
      shipment: Boolean,
      weighed: Boolean,
      shipmentDate: Date,
      marked: Boolean,
      markingCode: String,
      finalQuantity: Number,
      finalWeight: Number,
      measurementUnit: String,
      measurementStep: Number,
    },
    order: {
      id: Number,
      status: String
    },
    readOnly: Boolean,
  },
  validations() {
    return {
      product: { // requiredIf: () => false - значит поле красное
        finalQuantity: {
          requiredIf: () => !(!this.product.weighed &&
              (isNaN(parseInt(this.product.finalQuantity)) ||
                  parseInt(this.product.finalQuantity) > parseInt(this.product.quantity)))
        },
        finalWeight: {
          requiredIf: () => !(this.product.weighed &&
              (isNaN(parseInt(this.product.finalWeight)) ||
                  parseInt(this.product.finalWeight) > this.product.quantity * this.product.measurementStep))

        },
        shipmentDate: {
          requiredIf: () => !(this.product.weighed === true &&
              this.product.shipment === true &&
              !this.product.shipmentDate)
        },
        comment: {
          requiredIf: () => !(this.product.weighed === true &&
              this.product.shipment === false &&
              !this.product.comment)
        }
      }
    }
  },
  computed: {
    getMeasurementStep() {
      return this.product.measurementStep ?? 1
    },
    getTip() {
      return this.product.comment ?? 'Поставщик еще не определился'
    },
    canEdit() {
      return this.order.status === 'IN_PROCESS';
    },
    getOrderedAMount() {
      return this.product.weighed ? this.product.quantity * this.product.measurementStep : this.product.quantity
    },
  },
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    updateProductInfo() {
      if (this.product.shipment) {
        this.product.comment = null;
      }
      if (!this.product.shipment) {
        this.product.finalQuantity = null;
        this.product.shipmentDate = null;
      }
      if (this.product.shipment === null) {
        this.product.comment = null;
      }
    },
    onSubmit() {
      this.$v.product.$touch();
      if (this.submitting || this.$v.product.$anyError) {
        return;
      }

      this.updateProductInfo();
      this.submitting = true;
      return orderService.updateOrderItem(this.$props.product, this.$props.order.id)
          .then(() => {
            toastUtils.success(this.$bvToast, 'Информация обновлена')
          }).catch((resp) => {
            let message;
            if (resp.response.status === 404) {
              message = "Товар не найден. Сначала следует сохранить товар в заказе."
            } else {
              message = 'Произошла непредвиденная ошибка при обновлении'
            }
            toastUtils.error(this.$bvToast, message)
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    setDeletingItem(id) {
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить товар?', {
            title: 'Удаление товара',
            titleTag: 'h6',
            okVariant: 'success',
            okTitle: 'Отмена',
            cancelTitle: 'Удалить',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              return;
            }
            this.$emit('deleteItem', id)
            this.$emit("updateCost")
          });
    },
    handleEditClick() {
      if (this.editingEnabled) {
        this.$v.product.$touch();
        if (this.submitting || this.$v.product.$anyError) {
          toastUtils.error(this.$bvToast, 'Ошибка заполнения полей. Проверьте корректность введенных данных')
          return
        }

        if (!this.product.quantity) {
          toastUtils.error(this.$bvToast, 'Введите количество товара большее 0')
          return
        }
        if (this.product.quantity <= this.initialAmount) {
          this.$emit("updateCost")
          this.editingEnabled = false
          return
        }

        catalogService.getProduct(this.product.id)
            .then(resp => {
              if (resp.data.amount < this.product.quantity) {
                toastUtils.error(this.$bvToast, `Недостаточно товара на складе.
                Количество на складе ${resp.data.amount}.`)
                return
              }
              this.$emit("updateCost")
              this.editingEnabled = false
            })
        return;
      }

      this.initialAmount = this.product.finalQuantity ?? 0
      this.editingEnabled = true
    },
    numericFormat(value) {
      let numericValue = value.replace(/\D/g, '')
      if (numericValue.charAt(0) === "0") {
        numericValue = numericValue.slice(1)
      } else if (numericValue.length > 6) {
        numericValue = numericValue.slice(0, -1)
      }
      return numericValue;
    },
  }
}
</script>
<style>
.circle {
  height: 15px;
  width: 15px;
  background-color: var(--error-color);
  border-radius: 50%;
  display: inline-block;
}

.special {
  font-weight: bold;
  text-decoration: underline;
}

.pdr {
  padding-right: 2px !important
}

.pdl {
  padding-left: 2px !important
}

.error-feedback {
  color: var(--error-color);
  font-size: 0.875em;
  margin-top: 0.25rem;
}
</style>
