<template>
  <div style="width: 100%;">
    <b-col>
      <b-row>
        <b-button v-b-toggle.add-item-collapse variant="primary" style="width: 10em">Добавить товар</b-button>
      </b-row>
      <b-row>
        <b-collapse id="add-item-collapse" style="width: 100%" class="mt-2">
          <b-card>
            <v-select :options="normalizeVariants" v-model="selectedVariant"
                      :filterable="false"
                      @open="onOpen" @close="onClose" @search="fetchQuery">
              <template #list-footer>
                <li ref="load" class="loader" v-show="hasMoreElements">
                  Загрузка следующих элементов...
                </li>
              </template>
              <div slot="no-options">Нет подходящих товаров</div>
            </v-select>
            <b-button class="mt-2" v-b-toggle.add-item-collapse @click="clickHandle">Добавить</b-button>
          </b-card>
        </b-collapse>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import catalogService from "@/modules/catalog-service";
import vSelect from "vue-select";
import toastUtils from "@/utils/toast-utils";

export default {
  name: "product-create",
  components: {
    vSelect,
  },
  emits: ['updateCost'],
  props: {
    products: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      productList: {
        currentPage: 1,
        perPage: 20,
      },
      hasMoreElements: true,
      selectedVariant: '',
      submitting: false,
      variants: [],
    };
  },
  mounted() {
    this.fetchQuery("")
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  computed: {
    normalizeVariants() {
      return this.variants.map(variant => this.normalizeText(variant))
    },
  },
  methods: {
    async onOpen() {
      await this.$nextTick()
      this.observer.observe(this.$refs.load)
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await catalogService.getByQuery(this.selectedVariant, this.productList.perPage, ++this.productList.currentPage)
            .then((resp) => {
              this.hasMoreElements = resp.data.length !== 0
              resp.data.forEach((item) => this.variants.push(item))
            })
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    fetchQuery(query) {
      this.selectedVariant = query
      this.productList.currentPage = 1;
      catalogService.getByQuery(query, this.productList.perPage, 1)
          .then((resp) => {
            this.variants = resp.data
            this.hasMoreElements = true
          })
    },
    clickHandle() {
      if (this.selectedVariant === null) {
        return;
      }

      this.submitting = true;
      this.handleResponse(catalogService.getOrderList(this.selectedVariant.match(/код:\s(\d+);/)[1]));
    },
    handleResponse(axiosCall) {
      axiosCall
          .then((resp) => {
            if (this.products.some(obj => obj.id === resp.data.id)) {
              toastUtils.error(this.$bvToast, "Невозможно добавить дубликат товара")
              return;
            }
            this.products.push(resp.data)
            this.$emit("updateCost")
          })
          .catch(e => {
            if (e.isAxiosError) {
              let message;
              if (e.response?.status === 403) {
                message = 'Недостаточно прав на создание'
              } else if (e.response?.status === 409) {
                message = 'Недостаточное количество товара на складе'
              } else if (e.response?.status === 404) {
                message = 'Товар с таким id не найден'
              } else {
                message = 'Возникла неизвестная ошибка при добавлении товара к заказу'
              }
              toastUtils.error(this.$bvToast, message)
            }
            throw e;
          })
          .finally(() => {
            this.submitting = false;
            this.selectedVariant = null;
          });
    },
    normalizeText(product) {
      return `${product.name}; код: ${product.productCode}; остаток: ${product.amount}`
    },
  }
}
</script>
